import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import useAuth from 'hooks/useAuth'

const AuthContext = createContext();


function AuthProvider({ children }) {
  const { signed, signIn, loading, user, logOut } = useAuth();

  if (loading) {
    return (<CircularProgress />);
  }

  return (
    <AuthContext.Provider value={{ signed, signIn, loading, user, logOut }}>
      {children}
    </AuthContext.Provider>

  );
}

AuthProvider.propTypes = {
  children: PropTypes.object
};


export { AuthContext, AuthProvider }