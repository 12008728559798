import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import If from '../if';
import SolicitacoesDetalhes from '../SolicitacoesDetalhes';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  button: {
    display: 'inline-block',
    padding: 16
  }
}));

const SolicitacoesActions = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);



  const handleOpen = () => {
    setOpen(true);
  };

  const handleDownload = (event, solicitacaoValue) => {
    event.preventDefault();
    props.fazDownload(solicitacaoValue);
  }

  const handleCancel = (event, solicitacaoValue) => {
    event.preventDefault();
    props.cancelar(solicitacaoValue);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const { solicitacao } = props;
  var { status, pathArquivo } = solicitacao;
  return (
    <>
      <If test={status === 2 || status === 3}>
        <IconButton
          className={classes.button}
          onClick={handleOpen}
        >
          <Tooltip title="Detalhes">
            <ListAltOutlinedIcon color="secondary" />
          </Tooltip>

        </IconButton>

      </If>
      <SolicitacoesDetalhes
        handleClose={handleClose}
        open={open}
        solicitacao={solicitacao}
      />
      <If test={pathArquivo !== null && pathArquivo !== '' && status === 2}>
        <IconButton
          className={classes.button}
          onClick={e => handleDownload(e, solicitacao)}
        >
          <Tooltip title="Download">
            <CloudDownloadOutlinedIcon color="secondary" />
          </Tooltip>
        </IconButton>


      </If>
      <If test={pathArquivo === null || pathArquivo === ''}>
        <IconButton
          className={classes.button}
          onClick={e => handleCancel(e, solicitacao)}
        >
          <Tooltip title="Cancelar">
            <CancelIcon color="error" />
          </Tooltip>
        </IconButton>


      </If>

    </>
  );
};

SolicitacoesActions.propTypes = {
  cancelar: PropTypes.func,
  fazDownload: PropTypes.func,
  solicitacao: PropTypes.object
};


export default SolicitacoesActions;
