import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import SolicitacoesStatus from '../SolicitacoesStatus';
import {
  Card,
  CardContent,
  CardActions,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
} from '@material-ui/core';
import If from '../if';
import { ETiposDocumento } from '../../data';
import SolicitacoesActions from '../SolicitacoesActions';
import TableHeader from '../TableHeader/TableHeader';
import useFileDownloader from 'hooks/useFileDownloader';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 768,
    marginTop: theme.spacing(1)
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableRow: {
    height: 1,
    margin: 0
  },
  tableCell: {
    padding: '0px 16px'
  }
}));

const SolicitacoesTable = props => {
  const { className, cancelar, listaSolicitacoes, ...rest } = props;

  const classes = useStyles();

  const pegaDescricaoTipoDocumento = (tipoDoc) => {
    var retorno = ETiposDocumento.find(x => x.value === tipoDoc);
    return retorno && retorno !== undefined ? retorno.label : 'INDEFINIDO';
  }


  const [orderDirection, setOrderDirection] = React.useState('desc')
  const [valueToOrderBy, setValueToOrderBy] = React.useState('dataSolicitacao')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleRequestSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }


  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  const download = (file) => downloadFile(file);

  const fazDownload = (solicitacao) => {
    download(
      {
        file: `http://apicontador.metaposto.com.br/api/v1/solicitacao/download/${solicitacao.id}`,
        //file: 'http://apicontador.metaposto.com.br/api/v1/solicitacao/download/c4bc1b00d4c544b38b210f7b',
        filename: `${pegaDescricaoTipoDocumento(solicitacao.tipoDocumento)}_${solicitacao.cnpjEstabelecimento}_${solicitacao.competencia}`,
        name: `${pegaDescricaoTipoDocumento(solicitacao.tipoDocumento)}`
      })
  }

  const executaCancelamento = (solicitacao) => {
    cancelar(solicitacao.id);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1]
    });
    return stabilizedRowArray.map((el) => el[0]);

  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0)
  }

  const formataDadosAdicionais = (dados) => {

    return ` - Período: ${dados.dataInicial?.substr(0, 10)} à ${dados.dataFinal?.substr(0, 10)} ${'\n'}
      ${dados.dadosAdicionais}
      `;
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer component={Paper}>
            <Table>
              <TableHeader
                handleRequestSort={handleRequestSort}
                orderDirection={orderDirection}
                valueToOrderBy={valueToOrderBy}
              />
              <If test={typeof listaSolicitacoes !== undefined}>
                <TableBody>
                  {sortedRowInformation(listaSolicitacoes, getComparator(orderDirection, valueToOrderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(user => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={user.id}
                      >
                        <TableCell>
                          <Tooltip title={formataDadosAdicionais(user)} >
                            <div className={classes.nameContainer}>
                              <Typography variant="body1">{pegaDescricaoTipoDocumento(user.tipoDocumento)}</Typography>
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {user.competencia ? `${user.competencia.toString().substr(0, 4)}/${user.competencia.toString().substr(4, 2)}` : ''}
                        </TableCell>
                        <TableCell align="center">
                          {moment(user.dataSolicitacao).format('DD/MM/YYYY')}
                        </TableCell>
                        <SolicitacoesStatus solicitacao={user} />
                        <SolicitacoesActions
                          cancelar={executaCancelamento}
                          fazDownload={fazDownload}
                          solicitacao={user}
                        />
                      </TableRow>
                    ))}
                </TableBody>
              </If>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={listaSolicitacoes.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      {downloaderComponentUI}
    </Card >
  );
};

SolicitacoesTable.propTypes = {
  cancelar: PropTypes.func,
  className: PropTypes.string,
  listaSolicitacoes: PropTypes.array.isRequired
};

export default SolicitacoesTable;
