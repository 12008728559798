import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton, Modal, Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ETiposDocumento } from '../../data';
import moment from 'moment';
import If from '../if';
import useFileDownloader from 'hooks/useFileDownloader';
import api from '../../../../services/api'
import { AuthContext } from 'contexts/auth';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #1A75BC',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  card: {
    maxWidth: 400,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center'
  },
  avatar: {
    backgroundColor: '#1A75BC',
  },
  title: {
    color: '#1A75BC',
  },
  resendButton: {
    // to make a red delete button    
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    background: theme.palette.error.main,
  }
}));


const SolicitacoesDetalhes = props => {
  const { open, solicitacao } = props;
  const { logOut } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const fecharFormulario = (event) => {
    event.preventDefault();
    props.handleClose();
  }

  const reenviarSolicitacao = (event, solicitacao) => {
    //event.preventDefault();

    const dados =
    {
      id: solicitacao.id,
      cnpjEstabelecimento: solicitacao.cnpjEstabelecimento,
      tipoDocumento: solicitacao.tipoDocumento,
      competencia: solicitacao.competencia,
      dataInicial: solicitacao.dataInicial,
      dataFinal: solicitacao.dataFinal,
      status: 0,
      mensagem: 'Solicitação reenviada, aguardando reprocessamento',
      dataSolicitacao: moment().format(),
      gerarApos: solicitacao.gerarApos,
      dadosAdicionais: solicitacao.dadosAdicionais
    };

    api.put(`solicitacao/alterar/${solicitacao.id}`, dados)
      .then(() => {
        enqueueSnackbar('Solicitação reenviada com sucesso!', { variant: 'success' });
      })
      .catch(function (error) {
        console.error(error.toJSON());
        if (error.response.status === 401) {
          enqueueSnackbar('A sessão do usuário expirou, faça login novamente!', { variant: 'info' });
          logOut();
        }
        else
          enqueueSnackbar('Erro ao reenviar solicitacao', { variant: 'error' });
      });
    props.handleClose();
    window.location.reload();
  }

  const pegaDescricaoTipoDocumento = (tipoDoc) => {
    var retorno = ETiposDocumento.find(x => x.value === tipoDoc);
    return retorno && retorno !== undefined ? retorno.label : 'INDEFINIDO';
  }

  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  const download = (file) => downloadFile(file);

  const classes = useStyles();

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      aria-describedby="transition-modal-description"
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      closeAfterTransition
      open={open}
    >
      <div >
        <Card
          className={classes.card}
        >
          <CardHeader
            action={
              <IconButton onClick={e => fecharFormulario(e)}>
                <CloseOutlinedIcon />
              </IconButton>
            }
            avatar={
              <Avatar
                aria-label="Recipe"
                className={classes.avatar}
              >
                i
              </Avatar>
            }
            classes={{
              title: classes.title,
            }}
            subheader="Informações da solicitação selecionada."
            title="Detalhes"
            titleTypographyProps={{ variant: 'h4' }}
          />
          <Divider />
          <CardContent>
            <Typography
              component="h5"
              style={{ fontWeight: 600 }}
            >
              {`${pegaDescricaoTipoDocumento(solicitacao.tipoDocumento)} - Id (${solicitacao.id})`}
            </Typography>
            <Typography component="h6">
              {solicitacao.competencia ? `Competência: ${solicitacao.competencia.toString().substr(0, 4)}/${solicitacao.competencia.toString().substr(4, 2)}` : ''}
            </Typography>
            <Typography component="h6">
              {solicitacao.dataUpload ? `Data do arquivo: ${moment(solicitacao.dataUpload).format('DD/MM/YYYY HH:mm:ss')}` : ''}
            </Typography>
            <Typography
              component="h6"
              style={{ fontWeight: 600 }}
            >
              Mensagem:
            </Typography>
            <Typography

              component="p"
            >
              {solicitacao.mensagem}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions}>
            <If test={solicitacao.status === 3 || solicitacao.status === 2 || solicitacao.pathArquivo === ''} >

              <Button
                className={classes.resendButton}
                onClick={e => reenviarSolicitacao(e, solicitacao)}
                size="small"
                variant="contained"
              >
                Reenviar
              </Button>

            </If>
            <If test={solicitacao.status === 2 || solicitacao.pathArquivo !== ''}>

              <Button
                color="secondary"
                onClick={() => download(
                  {
                    file: `http://apicontador.metaposto.com.br/api/v1/solicitacao/download/${solicitacao.id}`,
                    //file: 'http://apicontador.metaposto.com.br/api/v1/solicitacao/download/c4bc1b00d4c544b38b210f7b',
                    filename: `${pegaDescricaoTipoDocumento(solicitacao.tipoDocumento)}_${solicitacao.cnpjEstabelecimento}_${solicitacao.competencia}`,
                    name: `${pegaDescricaoTipoDocumento(solicitacao.tipoDocumento)}`
                  }
                )}
                size="small"
                variant="contained"
              >
                Download
              </Button>
            </If>
          </CardActions>
        </Card>
        {downloaderComponentUI}
      </div>
    </Modal >
  );
};

SolicitacoesDetalhes.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  solicitacao: PropTypes.object
};

export default SolicitacoesDetalhes;
