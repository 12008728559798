import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  TextField,
} from '@material-ui/core';

import Search from '../../../../components/Search';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  statusInput: {
    width: '220px'
  }
}));

const SolicitacoesToolbar = props => {
  const { className, executaFiltro, executaFiltroStatus, ...rest } = props;

  const classes = useStyles();

  const [expressaoBusca, setExpressaoBusca] = useState('');
  const [filtroStatus, setFiltroStatus] = useState(-1);

  const handleSearchChange = (expressao) => {
    setExpressaoBusca(expressao);
  }

  const handleStatusChange = event => {
    setFiltroStatus(event.target.value);
  };

  const status = [
    {
      value: -1,
      label: 'Todos'
    },
    {
      value: 0,
      label: 'Pendente processamento'
    },
    {
      value: 1,
      label: 'em Processamento'
    },
    {
      value: 2,
      label: 'Processado'
    },
    {
      value: 3,
      label: 'Processado com Erro'
    }
  ];

  useEffect(() => {
    executaFiltro(expressaoBusca);
    executaFiltroStatus(filtroStatus);
  }, [expressaoBusca, executaFiltro, filtroStatus, executaFiltroStatus])

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={8}
          xs={6}
        >
          <Search
            disabled={false}
            onChange={handleSearchChange}
            placeholder="Localizar solicitação"
          />
        </Grid>

        <Grid
          item
          md={4}
          xs={6}
        >
          <TextField
            className={classes.statusInput}
            fullWidth
            label="Filtrar por status:"
            margin="dense"
            name="filtroStatus"
            onChange={handleStatusChange}
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={filtroStatus}
            variant="outlined"
          >
            {status.map(option => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>

    </div>
  );
};

SolicitacoesToolbar.propTypes = {
  className: PropTypes.string,
  executaFiltro: PropTypes.func,
  executaFiltroStatus: PropTypes.func
};

export default SolicitacoesToolbar;
