import React, { useEffect, useState } from 'react';
import './index.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Typography, ListItem } from '@material-ui/core';

import api from '../../services/api';


import LinearProgressWithLabel from './LinearProgressWithLabel';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const DownloadItem = ({ name, file, filename, removeFile }) => {
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    error: false,
    total: 0,
    loaded: 0,
  });

  const [infoArquivo, setInfoArquivo] = useState(name);

  const classes = useStyles();

  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          error: false,
          total,
          completed: false,
        });
      },
    };

    api.get(file, {
      responseType: 'blob',
      ...options,
    }).then(function (response) {
      //console.log(response);

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers['content-type'],
        })
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      setDownloadInfo((info) => ({
        ...info,
        completed: true,
      }));

      setTimeout(() => {
        removeFile();
      }, 4000);
      //console.log('passou aq')
    }).catch(function (error) {

      setDownloadInfo((info) => ({
        ...info,
        error: true,
        progress: 0,
        completed: true,
      }));
      setTimeout(() => {
        removeFile();
      }, 5000);

      if (error.response.status === 400) {
        try {
          //console.log(error.response.data);
          let fr = new FileReader();
          fr.readAsText(error.response.data);
          //console.log(fr)
          fr.onloadend = function (e) {
            const errRes = e.currentTarget['result'];
            const error = JSON.parse(errRes);
            //console.log(error.errors.Mensagens);
            setInfoArquivo(error.errors.Mensagens.toString(','));
          }
        }
        catch (e) {
          setInfoArquivo('Erro ao baixar arquivo!');
          return Promise.reject(e);
        }
      } else if (error.response.status === 401) {
        setInfoArquivo('A sessão do usuário expirou, faça o login novamente!');
      } else if (error.response.status === 404) {
        setInfoArquivo('Erro ao baixar arquivo, arquivo não encontrado!');
      }



      //console.error('erro ' + error.response.data);
    });
  }, []);

  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

  const finalizou = !(downloadInfo.loaded > 0 && !downloadInfo.completed);

  return (
    <ListItem>
      <div className={classes.root}>
        <div>
          <Typography
            component="h6"
            style={{ fontWeight: 600 }}
          >{infoArquivo}</Typography>

          {!finalizou && (
            <Typography
              component="h6"
            >
              <span className="text-success">
                {formatBytes(downloadInfo.loaded)}
              </span>
              / {formatBytes(downloadInfo.total)}
            </Typography>
          )}

          {downloadInfo.loaded === 0 &&
            <Typography
              component="h5"
            >
              {'Iniciando download...'}
            </Typography>}

          {downloadInfo.completed && (!downloadInfo.error) &&
            <CheckCircleIcon style={{ fill: 'green' }} />
          }

          {downloadInfo.error &&
            <ErrorIcon style={{ fill: 'red' }} />
          }


        </div>
        <div>
          <LinearProgressWithLabel
            value={downloadInfo.progress}
          />
        </div>
      </div>
    </ListItem>
  );
};

DownloadItem.propTypes = {
  file: PropTypes.string,
  filename: PropTypes.string,
  name: PropTypes.string,
  removeFile: PropTypes.func
};

export default DownloadItem;
