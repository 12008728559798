export const ETiposDocumento = [
  {
    value: 1,
    label: 'SPED FISCAL'
  },
  {
    value: 2,
    label: 'EFD CONTRIBUIÇÕES'
  },
  {
    value: 3,
    label: 'NFCe'
  },
  {
    value: 4,
    label: 'NFe'
  },
  {
    value: 9,
    label: 'NFe Entrada'
  },
  {
    value: 15,
    label: 'NF Entrada'
  },
  {
    value: 12,
    label: 'CTe Prestação'
  },
  {
    value: 5,
    label: 'CTe Aquisição'
  },
  {
    value: 6,
    label: 'NFSe'
  },
  {
    value: 7,
    label: 'CFe SAT'
  },
  {
    value: 13,
    label: 'MDFe'
  },
  {
    value: 8,
    label: 'DIEF'
  },
  {
    value: 10,
    label: 'SINTEGRA'
  },
  {
    value: 11,
    label: 'SEF II'
  },
  {
    value: 14,
    label: 'LMC'
  },

];

export const EStatusProcessamento = [
  {
    value: 0,
    label: 'Todos'
  },
  {
    value: 1,
    label: 'Processado'
  },

  {
    value: 3,
    label: 'Cancelado'
  },

  {
    value: 5,
    label: 'Denegado'
  },


];
