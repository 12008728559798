import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, Tooltip } from '@material-ui/core';
import TimerSharpIcon from '@material-ui/icons/TimerSharp';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';

import If from '../if';


const SolicitacoesStatus = props => {
  const { solicitacao } = props;
  var { status, pathArquivo, id } = solicitacao;
  pathArquivo = pathArquivo ?? '';
  return (
    <TableCell align="center">
      <If test={status === 0}>
        <Tooltip title={`Pendente processamento (${id})`}>
          <TimerSharpIcon color="action" />
        </Tooltip>
      </If>
      <If test={status === 1}>
        <Tooltip title={`em Processamento (${id})`}>
          <AlarmOnOutlinedIcon color="secondary" />
        </Tooltip>
      </If>
      <If test={status === 2 && pathArquivo !== ''}>
        <Tooltip title="Processado">
          <CheckCircleOutlineIcon style={{ fill: 'green' }} />
        </Tooltip>
      </If>
      <If test={(status === 2 && pathArquivo === '') || status === 3}>
        <Tooltip title="Processado com erro">
          <BugReportOutlinedIcon color="error" />
        </Tooltip>
      </If>
    </TableCell>
  );
};

SolicitacoesStatus.propTypes = {
  solicitacao: PropTypes.object
};


export default SolicitacoesStatus;
