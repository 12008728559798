import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { cpfCnpj } from '../../../../helpers/mascaras';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableContainer,
  Paper,
  CardActions,
  TablePagination,
  Tooltip,
  Typography,
  Link
} from '@material-ui/core';
import moment from 'moment';
import TableHeader from '../TableHeader/TableHeader';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';



const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const EstabelecimentoTable = props => {
  const { className, empresas, listarsolicitacoes, ...rest } = props;

  const classes = useStyles();

  const exibeSolicitacoes = (event, cnpj, nome) => {
    event.preventDefault();
    listarsolicitacoes(cnpj, nome);
  }

  const [orderDirection, setOrderDirection] = React.useState('asc')
  const [valueToOrderBy, setValueToOrderBy] = React.useState('nome')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleRequestSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1]
    });
    return stabilizedRowArray.map((el) => el[0]);

  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0)
  }

  const pegaInfoSolicitacoes = (dadosEmpresa) => {
    var texto = '';

    texto += dadosEmpresa.quantidadeSolicitacaoFinalizada > 0
      ? `Finalizado: ${dadosEmpresa.quantidadeSolicitacaoFinalizada}` : ''

    texto += dadosEmpresa.quantidadeSolicitacaoEmProcessamento > 0
      ? ` | Processamento: ${dadosEmpresa.quantidadeSolicitacaoEmProcessamento}` : ''

    texto += dadosEmpresa.quantidadeSolicitacaoPendente > 0
      ? ` | Pendente: ${dadosEmpresa.quantidadeSolicitacaoPendente}` : ''

    texto += dadosEmpresa.quantidadeSolicitacaoComErro > 0
      ? ` | Erro: ${dadosEmpresa.quantidadeSolicitacaoComErro}` : ''

    return texto;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer component={Paper}>
            <Table>
              <TableHeader
                handleRequestSort={handleRequestSort}
                orderDirection={orderDirection}
                valueToOrderBy={valueToOrderBy}
              />
              <TableBody>
                {
                  sortedRowInformation(empresas, getComparator(orderDirection, valueToOrderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(empresa => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={empresa.id}
                      >
                        <TableCell>
                          <Link
                            color="inherit"
                            component="button"
                            onClick={e => exibeSolicitacoes(e, empresa.cnpj, empresa.nome)}
                            underline="none"
                          >
                            {empresa.nome}
                          </Link>
                          <Typography variant="subtitle2">{empresa.nomeFantasia}</Typography>
                        </TableCell>
                        <TableCell>{cpfCnpj(empresa.cnpj)}</TableCell>
                        <TableCell>
                          {moment(empresa.vencimento).format('DD/MM/YYYY') !== '31/12/0000'
                            ? moment(empresa.vencimento).format('DD/MM/YYYY')
                            : ''}
                        </TableCell>
                        <TableCell align="center">


                          <IconButton
                            color="secondary"
                            onClick={e => exibeSolicitacoes(e, empresa.cnpj, empresa.nome)}
                          >
                            <Tooltip title={pegaInfoSolicitacoes(empresa)}>
                              <DescriptionTwoToneIcon />
                            </Tooltip>
                          </IconButton>

                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>

      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={empresas.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>

    </Card >
  );
};

EstabelecimentoTable.propTypes = {
  className: PropTypes.string,
  empresas: PropTypes.array.isRequired,
  listarsolicitacoes: PropTypes.func,
};

export default EstabelecimentoTable;
