import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { SolicitacoesToolbar, SolicitacoesTable, SolicitacoesFormulario } from './components';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { ETiposDocumento } from './data';
import moment from 'moment';
import api from '../../services/api'
import { AuthContext } from 'contexts/auth';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  space: {
    marginTop: theme.spacing(1)
  }
}));

const headers = {};

const SolicitacoesList = () => {
  const { logOut } = useContext(AuthContext);
  const { cnpjEmpresa } = useParams();

  const classes = useStyles();

  const [solicitacoes, setSolicitacoes] = useState([]);
  const [solicitacoesFiltradas, setSolicitacoesFiltradas] = useState(solicitacoes);
  const [filtro, setFiltro] = useState('');
  const [filtroStatus, setFiltroStatus] = useState(-1);
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(0)

  const filtrar = (expressao) => {
    setFiltro(expressao);
  }

  const filtrarStatus = (expressao) => {
    setFiltroStatus(expressao);
  }

  const pegaTipoDocumento = (tipoDoc) => {
    const tipo = ETiposDocumento.find(x => x.label.toUpperCase().includes(tipoDoc.toUpperCase()));
    if (tipo)
      return tipo.value
  }

  useEffect(() => {
    setSolicitacoesFiltradas(solicitacoes);
    if (filtro.length > 0 || filtroStatus !== null) {
      //console.log(`filtrando lista ${filtro}`);
      const listaFiltrada = solicitacoes.filter(e =>
        e.tipoDocumento !== null &&
        (e.tipoDocumento === pegaTipoDocumento(filtro) ||
          moment(e.dataSolicitacao).format('DD/MM/YYYY').indexOf(filtro) > -1 ||
          (e.competencia !== null && e.competencia.toString().indexOf(filtro.replace('/', '')) > -1)
        ) &&
        (+filtroStatus === -1 || +filtroStatus === +e.status)
      );
      if (listaFiltrada !== null)
        setSolicitacoesFiltradas(listaFiltrada)
    }

  }, [filtro, filtroStatus, solicitacoes]);

  const tick = () => {
    setCount((prevState) => prevState < 180 ? prevState + 1 : 0);
  }

  //Utilizado para recarregamento da página em intervalos de tempo (3min aprox.)
  useEffect(() => {
    const timer = setInterval(() => tick(), 1000)
    return () => clearInterval(timer)
  });

  useEffect(() => {
    if (count === 0) {
      listarSolicitacoes(cnpjEmpresa);
      //console.log('recarregou página')
    }
  }, [count]);




  const handleadd = (values) => {
    const { mes, ano } = values;
    const dado = {
      id: new Date(),
      cnpjEstabelecimento: +cnpjEmpresa,
      tipoDocumento: +values.tiposDocumento,
      competencia: +((`${ano}`) + mes.toString().padStart(2, '0')),
      dataSolicitacao: moment().format(),
      dataInicial: values.dataInicial,
      dataFinal: values.dataFinal,
      dadosAdicionais: '',
      status: 0,
      pathArquivo: ''
    }
    const arquivosTexto = [1, 2, 8, 10, 11];
    if (arquivosTexto.includes(dado.tipoDocumento)) {
      const dia = (new Date(ano, mes, 0)).getDate();
      const dataInicial = `${ano.toString()}-${mes.toString().padStart(2, '0')}-01`;
      const dataFinal = `${ano.toString()}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
      dado.dataInicial = dataInicial.toString();
      dado.dataFinal = dataFinal.toString();

      if (values.gerarInventario) {
        var dadosJson = JSON.stringify({ gerarInventario: 1, dataInventario: values.dataInventario, motivoInventario: values.motivoInventario });
        dado.dadosAdicionais = dadosJson;
      }
    }
    else {

      const competencia = `${values.dataInicial.substr(0, 4)}${values.dataInicial.substr(5, 2)}`;
      dado.competencia = +competencia;

      var dadosJsonXml = JSON.stringify({ exportaRelatorio52: values.exportaRelatorio52 ? 1 : 0, statusProcessamento: +values.statusProcessamentoDocumento });
      dado.dadosAdicionais = dadosJsonXml;

    }
    console.log(dado);
    enviaSolicitacao(dado);
  }

  const existeSolicitacao = (dado) => {
    const valor = solicitacoes.filter(x => x.cnpjEstabelecimento == dado.cnpjEstabelecimento.toString().padStart(14, '0') &&
      x.tipoDocumento == dado.tipoDocumento &&
      x.competencia == dado.competencia.toString() &&
      x.dadosAdicionais == dado.dadosAdicionais &&
      x.dataInicial.substr(0, 10) == dado.dataInicial.substr(0, 10) &&
      x.dataFinal.substr(0, 10) == dado.dataFinal.substr(0, 10)
    )
    console.log(valor);
    if (valor)
      return valor.length > 0;
    else
      return false;
  }

  const enviaSolicitacao = (dado) => {
    if (!existeSolicitacao(dado)) {
      const dados = JSON.stringify(
        {
          cnpjEstabelecimento: dado.cnpjEstabelecimento.toString().padStart(14, '0'),
          tipoDocumento: dado.tipoDocumento,
          competencia: dado.competencia.toString(),
          dadosAdicionais: dado.dadosAdicionais,
          dataInicial: dado.dataInicial,
          dataFinal: dado.dataFinal,
          status: 0,
          dataSolicitacao: moment().format()
        });
      api.defaults.headers.post['Content-Type'] = 'application/json';
      api.post('solicitacao/adicionar', dados)
        .then((response) => {
          enqueueSnackbar('Solicitação enviada com sucesso!', { variant: 'success' });
          setSolicitacoes([...solicitacoes, response.data]);
          listarSolicitacoes(dado.cnpjEstabelecimento);
          //console.log(response);
        })
        .catch(function (error) {
          console.error(error.toJSON());
          if (error.response.status === 401) {
            enqueueSnackbar('A sessão do usuário expirou, faço login novamente!', { variant: 'info' });
            logOut();
          }
          else
            enqueueSnackbar('Erro ao enviar solicitacao', { variant: 'error' });
        });
    }
    else
      enqueueSnackbar('Solicitação já enviada, aguarde processamento, faça o reenvio ou cancele!', { variant: 'warning' });

  }

  const listarSolicitacoes = (cnpjEstabelecimento) => {
    console.log('listando solicitacoes')
    api.get(`solicitacao/listar-solicitacoes-estabelecimento/${cnpjEstabelecimento}`, {
      headers: headers
    }).then(response => {
      const listaSolicitacoes = response.data;
      setSolicitacoes(listaSolicitacoes.filter(e => e.status !== 4));
      setSolicitacoesFiltradas(listaSolicitacoes.filter(e => e.status !== 4));
    }).catch(error => {
      if (error.response?.status === 401) {
        enqueueSnackbar('A sessão do usuário expirou, faça login novamente!', { variant: 'info' });
        logOut();
      }
      console.error(`erro ao listar empresas: ${error}`);
    })

  }

  const cancelarSolicitacao = (id) => {
    console.log(cnpjEmpresa)
    const dados =
    {
      id: id,
      cnpjEstabelecimento: cnpjEmpresa,
      status: 4,
      mensagem: 'Solicitação cancelada',
      dataSolicitacao: moment().format()
    };

    api.put(`solicitacao/alterar/${id}`, dados)
      .then(() => {
        enqueueSnackbar('Solicitação cancelada com sucesso!', { variant: 'success' });
        listarSolicitacoes(cnpjEmpresa);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          enqueueSnackbar('A sessão do usuário expirou, faça login novamente!', { variant: 'info' });
          logOut();
        }
        else
          enqueueSnackbar('Erro ao cancelar solicitacao', { variant: 'error' });
      });


  }

  return (
    <div className={classes.root}>

      <div className={classes.content}>
        <SolicitacoesFormulario handleadd={handleadd} />
      </div>
      <div />

      <div className={classes.content}>
        <SolicitacoesToolbar
          executaFiltro={filtrar}
          executaFiltroStatus={filtrarStatus}
        />
        <div className={classes.space} />
        <SolicitacoesTable
          cancelar={cancelarSolicitacao}
          listaSolicitacoes={solicitacoesFiltradas}
        />
      </div>
    </div>
  );
};

export default SolicitacoesList;

