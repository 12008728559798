import React from 'react';
import './index.css';
import { makeStyles } from '@material-ui/styles';
import { List, Paper, Card, CardContent, CardHeader } from '@material-ui/core';
import DownloadItem from './DownloadItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  downloader: {
    width: '500px',
    minHeight: '128px',
    position: 'fixed',
    right: '18px',
    bottom: '18px',
    maxHeight: '700px',
    //overflowY: 'auto',
  },
  cardHeader: {
    backgroundColor: theme.palette.disabled.main
  },
  card: {
    maxHeight: '300px',
    //overflow: 'hidden',
    //overflowY: 'auto',    
  },

}));

const Downloader = ({ files = [], remove }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.downloader}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title="Baixando arquivo"
        />
        <CardContent>
          <List >
            {files.map((file, idx) => (
              <DownloadItem
                key={idx}
                removeFile={() => remove(file.downloadId)}
                {...file}
              />
            ))}
          </List >
        </CardContent>
      </Card>
    </Paper>
  );
};

Downloader.propTypes = {
  files: PropTypes.array,
  remove: PropTypes.func
};

export default Downloader;
