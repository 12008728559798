import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { EstabelecimentoToolbar, EstabelecimentoTable } from './components';
//import axios from 'axios';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import api from '../../services/api'


import { AppContext } from 'contexts/Store';
import { AuthContext } from 'contexts/auth';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));


//const API_URL = 'http://localhost:3000/estabelecimentos';
const headers = {};



const EstabelecimentoList = (props) => {
  const { history } = props;
  const classes = useStyles();

  //const contexto = useContext(DataContext);
  const { setDadosEmpresa } = useContext(AppContext);
  const { logOut } = useContext(AuthContext);

  /*
  const empresa = {
    nome: string,
    cnpj: string,
    vctoCertificado: string,
  }
  */

  const [empresas, setEmpresas] = useState([]);
  //const [empresas] = useState(mockData);

  const [empresasFiltradas, setEmpresasFiltradas] = useState(empresas);

  const [filtro, setFiltro] = useState('');

  const filtrar = (expressao) => {
    setFiltro(expressao);
  }

  const storedUser = localStorage.getItem('@portalContador:usuario');
  const cpfContadorLogado = storedUser ? JSON.parse(storedUser).cpf : '';

  useEffect(() => {
    setEmpresasFiltradas(empresas);
    if (filtro.length > 0) {
      const listaFiltrada = empresas.filter(e => e.nome.toUpperCase().indexOf(filtro.toUpperCase()) > -1 ||
        (e.nomeFantasia !== null && e.nomeFantasia !== '' && e.nomeFantasia.toUpperCase().indexOf(filtro.toUpperCase()) > -1) ||
        e.cnpj.toString().indexOf(filtro) > -1);
      setEmpresasFiltradas(listaFiltrada)
    }

  }, [filtro, empresas]);

  /*
useEffect(() => {
listarEmpresas(cpfContadorLogado);
//contexto.setState({ nomeRazao: 'PORTAL DO CONTADOR', cnpj: null })        
}, []);
*/

  /*
useEffect(() => {
(async () => {
  await api.get(`estabelecimentos?cpfContador=${cpfContadorLogado}`, {
    headers: headers
  }).then(response => {
    const listaEmpresas = response.data;
    setEmpresas(listaEmpresas);
    setEmpresasFiltradas(listaEmpresas);
    setDadosEmpresa(null, null);
    //console.log(listaEmpresas);

  }).catch(erro => {
    console.error(`erro ao listar empresas: ${erro}`);
  })
})();
}, []);
*/

  useEffect(() => {
    //console.log('vai carregar estabelecimento', 'cpf ', { cpfContadorLogado });
    (async () => {
      await api.get(`estabelecimento/listar-estabelecimento-contador/${cpfContadorLogado}`, {
        headers: headers
      }).then(response => {
        const listaEmpresas = response.data;
        setEmpresas(listaEmpresas);
        setEmpresasFiltradas(listaEmpresas);
        setDadosEmpresa(null, null);
        //console.log(listaEmpresas);

      }).catch(erro => {
        if (erro.response.status === 401) {
          logOut();
        }
        console.error(`erro ao listar empresas: ${erro}`);
      })
    })();
  }, []);


  const exibirSolicitacoes = (cnpjEmpresa, nomeEmpresa) => {
    history.push(`/solicitacoes/${cnpjEmpresa}`);
    //contexto.setState({ nomeRazao: nomeEmpresa, cnpj: cnpjEmpresa })        
    setDadosEmpresa(nomeEmpresa, cnpjEmpresa);
  }

  return (
    <div className={classes.root}>
      <EstabelecimentoToolbar executaFiltro={filtrar} />
      <div className={classes.content}>
        <EstabelecimentoTable
          empresas={empresasFiltradas}
          listarsolicitacoes={exibirSolicitacoes}
        />
      </div>
    </div>
  );
};

EstabelecimentoList.propTypes = {
  history: PropTypes.object
};

export default withRouter(EstabelecimentoList);
