import { useState, useEffect } from 'react';

import api from 'services/api';
//import axios1 from 'axios';
import { useSnackbar } from 'notistack';
import history from '../../src/history';

const initialState = {
  signed: false,
  setSigned: () => { },
  signIn: () => { },
  loading: true,
  token: null,
  expiracaoEm: 0,
  user: { nome: '', cpf: 12173762285 }
}

export default function useAuth() {
  const [user, setUser] = useState(initialState.user);
  const [token, setToken] = useState(initialState.token);
  const [expiracaoEm, setExpiracaoEm] = useState(initialState.expiracaoEm);
  const [loading, setLoading] = useState(initialState.loading);
  const [signed, setSigned] = useState(initialState.signed);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

    const storedToken = localStorage.getItem('@portalContador:token');
    const storedUser = localStorage.getItem('@portalContador:usuario');
    const storedLogado = localStorage.getItem('@portalContador:signed');

    if (storedToken && storedUser) {
      setUser(storedUser);
      //setToken(storedToken);
      api.defaults.headers.authorization = `Bearer ${JSON.parse(storedToken)}`;
      setSigned(storedLogado === 'true');
    }

    //console.log('effect not loading')

    setLoading(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (expiracaoEm !== 0) {
        localStorage.removeItem('@portalContador:token');
        localStorage.removeItem('@portalContador:usuario');
        localStorage.removeItem('@portalContador:signed');
        setSigned(false)
        //console.log('expirou');
      }
      setExpiracaoEm(0);
    }, expiracaoEm * 60000);
    return () => clearTimeout(timer);
  }, [expiracaoEm]);

  function logOut() {
    localStorage.removeItem('@portalContador:token');
    localStorage.removeItem('@portalContador:usuario');
    localStorage.removeItem('@portalContador:signed');
    setSigned(false)
    history.push('/sign-in');
  }

  async function signIn(email, password) {
    try {
      const dados = JSON.stringify({ Email: email, Senha: password });

      api.defaults.headers.post['Content-Type'] = 'application/json';
      api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      api.defaults.headers.post['Acess-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';

      let data = null;
      api.defaults.headers.authorization = '';
      await api.post('/login/autenticar', dados)//await instance.post('login/autenticar', dados)
        .then((response) => { data = response.data })
        .catch(function (error) {
          console.error(error);
          console.log(error.toJSON());
        });

      if (data) {
        setUser({ nome: data.usuarioToken.nome, cpf: data.usuarioToken.cpf });
        setToken(data.tokenAcesso);
        setSigned(true);
        setExpiracaoEm(data.expiracaoEm);
        var obj = { nome: data.usuarioToken.nome, cpf: data.usuarioToken.cpf };
        var usuario = JSON.stringify(obj);
        api.defaults.headers.authorization = `Bearer ${data.tokenAcesso} `;
        localStorage.setItem('@portalContador:token', JSON.stringify(data.tokenAcesso));
        localStorage.setItem('@portalContador:usuario', usuario);
        localStorage.setItem('@portalContador:signed', 'true');

        history.push('/');
      }
      else {
        enqueueSnackbar('Erro ao realizar login, verifique email ou senha!', { variant: 'error' });
      }

    }
    catch (err) {
      setSigned(false);
      setLoading(false);
      console.error('ERRO ' + err);
    }

  }

  return { signed, signIn, loading, user, logOut }
}
