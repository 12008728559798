import React, { useState } from 'react';
import clsx from 'clsx';
import If from '../if';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { EStatusProcessamento, ETiposDocumento } from '../../data';

const useStyles = makeStyles((theme) => ({
  root: {},
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    flexDirection: 'flex-end'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));


const SolicitacoesFormulario = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ano = (new Date()).getFullYear();
  const mes = (new Date()).getMonth() + 1;
  const dia = (new Date(ano, mes, 0)).getDate();

  const [values, setValues] = useState({
    tiposDocumento: '4',
    statusProcessamentoDocumento: '0',
    mes: mes,
    ano: ano,
    dataInicial: `${ano}-${mes.toString().padStart(2, '0')}-01`,
    dataFinal: `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`,
    gerarInventario: false,
    motivoInventario: 0,
    dataInventario: `${(new Date()).getFullYear() - 1}-12-31`,
    exportaRelatorio52: false,
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleCheckInventario = () => {
    //console.log(values.gerarInventario)
    setValues({
      ...values,
      gerarInventario: !values.gerarInventario
    });
  };

  const handleCheckRelatorio52 = () => {
    setValues({
      ...values,
      exportaRelatorio52: !values.exportaRelatorio52
    });
  };

  const tiposDocumento = ETiposDocumento;
  const statusProcessamento = EStatusProcessamento;

  const arquivosTexto = ['1', '2', '8', '10', '11'];
  const arquivosXML = ['3', '4', '5', '6', '7', '9', '12', '13', '14', '15'];

  const meses = [
    {
      value: 1,
      label: 'JANEIRO'
    },
    {
      value: 2,
      label: 'FEVEREIRO'
    },
    {
      value: 3,
      label: 'MARÇO'
    },
    {
      value: 4,
      label: 'ABRIL'
    },
    {
      value: 5,
      label: 'MAIO'
    },
    {
      value: 6,
      label: 'JUNHO'
    },
    {
      value: 7,
      label: 'JULHO'
    },
    {
      value: 8,
      label: 'AGOSTO'
    },
    {
      value: 9,
      label: 'SETEMBRO'
    },
    {
      value: 10,
      label: 'OUTUBRO'
    },
    {
      value: 11,
      label: 'NOVEMBRO'
    },
    {
      value: 12,
      label: 'DEZEMBRO'
    }
  ];

  const motivosInventario = [
    {
      value: 1,
      label: '1) NO FINAL NO PERÍODO'
    },
    {
      value: 2,
      label: '2) NA MUDANÇA DE FORMA DE TRIBUTAÇÃO DA MERCADORIA (ICMS)'
    },
    {
      value: 3,
      label: '3) NA SOLICITAÇÃO DA BAIXA CADASTRAL, PARALISAÇÃO TEMPORÁRIA E OUTRAS SITUAÇÕES'
    },
    {
      value: 4,
      label: '4) NA ALTERAÇÃO DE REGIME DE PAGAMENTO – CONDIÇÃO DO CONTRIBUINTE'
    },
    {
      value: 5,
      label: '5) POR DETERMINAÇÃO DOS FISCOS'
    },
    {
      value: 6,
      label: '6) MERCADORIAS SUJEITAS A ST - RESTITUIÇÃO/RESSARCIMENTO/COMPLEMENTAÇÃO'
    }
  ];

  const handleSubmmit = event => {
    event.preventDefault();
    //console.log('enter');
  };

  const adicionaSolicitacao = (event) => {
    //console.log(values);
    event.preventDefault();
    props.handleadd(values);
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="on"
        noValidate
        onSubmit={handleSubmmit}
      >
        <CardHeader
          action={
            <IconButton
              aria-expanded={expanded}
              aria-label="expandir"
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
          }
          subheader="Informe os dados para uma nova solicitação."
          title="Solicitações"
        />
        <Divider />

        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                md={2}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Tipo Documento"
                  margin="dense"
                  name="tiposDocumento"
                  onChange={handleChange}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={values.tiposDocumento}
                  variant="outlined"
                >
                  {tiposDocumento.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <If test={arquivosTexto.includes(values.tiposDocumento)}>
                <Grid
                  item
                  md={2}
                  xs={5}
                >
                  <TextField
                    fullWidth
                    label="Competência"
                    margin="dense"
                    name="mes"
                    onChange={handleChange}
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={values.mes}
                    variant="outlined"
                  >
                    {meses.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={1}
                  xs={2}
                >
                  <TextField
                    fullWidth
                    inputProps={{ min: 2015, max: 2030, step: 1 }}
                    label=""
                    margin="dense"
                    name="ano"
                    onChange={handleChange}
                    type="number"
                    value={values.ano}
                    variant="outlined"
                  />
                </Grid>
              </If>

              <If test={arquivosXML.includes(values.tiposDocumento)}>
                <Grid
                  item
                  md={2}
                  xs={6}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Data Inicial"
                    margin="dense"
                    name="dataInicial"
                    onChange={handleChange}
                    type="date"
                    value={values.dataInicial}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={6}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Data Final"
                    margin="dense"
                    name="dataFinal"
                    onChange={handleChange}
                    type="date"
                    value={values.dataFinal}
                    variant="outlined"
                  />
                </Grid>
                <If test={values.tiposDocumento !== '14'}>
                  <Grid
                    item
                    md={2}
                    xs={5}
                  >
                    <TextField
                      SelectProps={{ native: true }}

                      fullWidth
                      label="Status:"
                      margin="dense"
                      name="statusProcessamentoDocumento"
                      onChange={handleChange}
                      select
                      value={values.statusProcessamentoDocumento}
                      variant="outlined"
                    >
                      {statusProcessamento.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </If>

                <If test={values.tiposDocumento !== '5' && values.tiposDocumento !== '9' && values.tiposDocumento !== '12' && values.tiposDocumento !== '15' && values.tiposDocumento !== '13' && values.tiposDocumento !== '14'}>
                  <Tooltip title="RELATÓRIO QUE APRESENTA OS DOCUMENTOS FISCAIS DE VENDA NO PERÍODO. SÃO APRESENTAS INFORMAÇÕES DAS VENDAS, OU SEJA, NÃO SERÃO APRESENTADOS DOCUMENTOS DE DEVOLUÇÃO OU QUE POSSUAM QUALQUER NATUREZA DIFERENTE DE VENDA E QUE NÃO POSSUAM VÍNCULO FINANCEIRO">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.exportaRelatorio52}
                          color="primary"
                          onChange={handleCheckRelatorio52}
                        />}
                      label="Incluir relatório 52"
                    />
                  </Tooltip>
                </If>

              </If>
              <If test={values.tiposDocumento === '1'}>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <span>
                    <Typography
                      gutterBottom
                      variant="h6"
                    >
                      Dados adicionais:
                    </Typography>
                    <Divider />
                  </span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gerarInventario}
                        color="primary"
                        onChange={handleCheckInventario}
                      />}
                    label="Gerar Inventário"
                  />

                  <TextField
                    disabled={!values.gerarInventario}
                    fullWidth
                    label="Motivo do inventário"
                    margin="dense"
                    name="motivoInventario"
                    onChange={handleChange}
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={values.motivoInventario}
                    variant="outlined"
                  >
                    {motivosInventario.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      disabled={!values.gerarInventario}
                      fullWidth
                      label="Data Inventário"
                      margin="dense"
                      name="dataInventario"
                      onChange={handleChange}
                      type="date"
                      value={values.dataInventario}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </If>
            </Grid>
          </CardContent>

          <CardActions>
            <Button
              color="primary"
              onClick={e => adicionaSolicitacao(e)}
              variant="contained"
            >
              Adicionar
            </Button>
          </CardActions>
        </Collapse>
      </form >
    </Card >
  );
};

SolicitacoesFormulario.propTypes = {
  className: PropTypes.string,
  handleadd: PropTypes.func,
};

export default SolicitacoesFormulario;
