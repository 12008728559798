import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function LinearProgressWithLabel(props) {
  return (
    <Box
      alignItems="center"
      display="flex"
    >
      <Box
        mr={1}
        width="100%"
      >
        <LinearProgress
          variant="determinate"
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          color="textSecondary"
          variant="body2"
        >{`${Math.round(props.value,)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
