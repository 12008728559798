import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import React from 'react'

export default function TableHeader(props) {
  const { valueToOrderBy, orderDirection, handleRequestSort } = props

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell key="nome">
          <TableSortLabel
            active={valueToOrderBy === 'nome'}
            direction={valueToOrderBy === 'nome' ? orderDirection : 'asc'}
            onClick={createSortHandler('nome')}
          >
            Nome Razão/Fantasia
          </TableSortLabel>
        </TableCell>
        <TableCell key="cnpj">
          <TableSortLabel
            active={valueToOrderBy === 'cnpj'}
            direction={valueToOrderBy === 'cnpj' ? orderDirection : 'asc'}
            onClick={createSortHandler('cnpj')}
          >
            CNPJ
          </TableSortLabel>
        </TableCell>
        <TableCell>Certificado</TableCell>
        <TableCell align="center">Arquivos</TableCell>
      </TableRow>
    </TableHead>
  )
}
