import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';



ReactDOM.render(
  <SnackbarProvider
    TransitionComponent={Slide}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    maxSnack={3}
  >
    <App />
  </SnackbarProvider>
  , document.getElementById('root'));

serviceWorker.unregister();
