import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import React from 'react'

export default function TableHeader(props) {
  const { valueToOrderBy, orderDirection, handleRequestSort } = props

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="tipoDocumento"
        >
          <TableSortLabel
            active={valueToOrderBy === 'tipoDocumento'}
            direction={valueToOrderBy === 'tipoDocumento' ? orderDirection : 'asc'}
            onClick={createSortHandler('tipoDocumento')}
          >
            Tipo Documento
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="center"
          key="competencia"
        >
          <TableSortLabel
            active={valueToOrderBy === 'competencia'}
            direction={valueToOrderBy === 'competencia' ? orderDirection : 'asc'}
            onClick={createSortHandler('competencia')}
          >
            Competência
          </TableSortLabel>
        </TableCell >
        <TableCell align="center">
          <TableSortLabel
            active={valueToOrderBy === 'dataSolicitacao'}
            direction={valueToOrderBy === 'dataSolicitacao' ? orderDirection : 'asc'}
            onClick={createSortHandler('dataSolicitacao')}
          >
            Data Solicitação
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          Status
        </TableCell>
        <TableCell align="left" />
      </TableRow>
    </TableHead>
  )
}
